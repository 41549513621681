const _engoThermostatProductId = [
  "zrk5ctgzhyaupkrn",
  "awnadkan",
  "cg8hdnjv",
  "gtouvmvl",
  "iczncfr5vuou25x9",
  "bowuem6d9ihzwymj",
  "cmyc8g5i",
  "f1tbiilmxdkvy9x2",
  "7frvwc6y3rh903az",
];


export const getTemperatureMultiplicationFactor = (productId, temperature) => {
  const adjustedTemperature = _engoThermostatProductId.includes(productId)
    ? temperature * 10
    : temperature;

  return adjustedTemperature;
};

export const recalculateTemperature = (temperature, productId) => {
  const adjustedTemperature = _engoThermostatProductId.includes(productId)
    ? temperature / 10
    : temperature;

  return adjustedTemperature;
};
