import React from "react";
// STYLES
import styled from "styled-components";
import { theme } from "../../utils/theme";
// COMPONENTS
import { Ikonka } from "./Icon";

const Infoline = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${theme.color.base6};
  border-radius: 20px;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;

  svg {
    fill: ${theme.color.base1};
    max-height: 24px;
    max-width: 24px;
  }

  &.active {
    background-color: ${theme.color.base1};
    svg {
      fill: ${theme.color.white};
    }
  }

  &.off {
    background-color: ${theme.color.second};
    opacity: 0.6;
    svg {
      fill: ${theme.color.secondLight};
      opacity: 0.8;
    }
  }
`;

function CircIcon({ name, className }) {
  return (
    <Infoline className={className}>
      <Ikonka name={name} />
    </Infoline>
  );
}

export default CircIcon;
