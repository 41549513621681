import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { MutatingDots } from "react-loader-spinner";

//STYLES
import { theme } from "../utils/theme";
import { breakpoint } from "styled-components-breakpoint";
//DATA

//COMPONENTS
import Layout from "../layout/Layout";
import Dashboard from "./main/Dashboard";
import Settings from "./main/Settings";
import Messages from "./main/Messages";
import MyHome from "./main/MyHome";
import Login from "./Login/login";
import TermostatSettings from "../components/Settings/TermostatSettings";
import RelaySettings from "../components/Settings/RelaySettings";
import ControlBoxSettings from "../components/Settings/ControlBoxSettings";
import { useAuth } from "../services/AuthContext";
import { Loader100 } from "../utils/utilsstyles";

function Main() {
  const { checkUserIsLoggedIn } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifyUser = async () => {
      const result = await checkUserIsLoggedIn();
      setIsLoggedIn(result);
      setIsLoading(false);
    };

    verifyUser();
  }, [checkUserIsLoggedIn]);

  if (isLoading) {
    // Optionally, you can render a loading indicator while checking auth status
    return (
      <Loader100>
        <MutatingDots
          visible={true}
          height="100"
          width="100"
          color={theme.color.base1}
          secondaryColor={theme.color.base1}
          radius="12.5"
          ariaLabel="mutating-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </Loader100>
    );
  }

  return (
    <div>
      {isLoggedIn ? (
        <Layout>
          <Routes>
            <Route path="/settings" element={<Settings title="Ustawienia" />} />
            <Route path="/messages" element={<Messages title="Wiadomości" />} />
            <Route
              path="/TermostatSettings/:id"
              element={<TermostatSettings />}
            />
            <Route path="/RelaySettings/:id" element={<RelaySettings />} />
            <Route
              path="/ControlBoxSettings/:id"
              element={<ControlBoxSettings />}
            />
            <Route
              path="/myhome"
              element={<MyHome title="Zarządzanie domem" />}
            />
            <Route path="/" element={<Dashboard />} />
          </Routes>
        </Layout>
      ) : (
        <Login />
      )}
    </div>
  );
}

export default Main;
