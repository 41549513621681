import {
  animate,
  motion,
  useMotionTemplate,
  useMotionValue,
  useTransform,
} from "framer-motion";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { theme } from "../../utils/theme";
import CircIconLabeled from "../Icons/CircIconLabeled";
import DebounceService from "../../services/DebounceService";

const SliderWrapper = styled.div`
  margin-top: 0.7rem;
  //margin-bottom: 2rem;
`;

const SliderBarWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 1rem;
  align-items: center;
`;

const SliderBar = styled.div`
  position: relative;
  height: 7px;

  .bar {
    width: 100%;
    height: 7px;
    border-radius: 10px;
  }

  div {
    position: absolute;
  }
`;

const Knob = styled.div`
  width: 100%;
  > div {
    z-index: 100;
    cursor: pointer;
    height: 24px;
    width: 24px;
    background-color: ${theme.color.base1};
    border-radius: 12px;
    border: 2px solid white;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-bottom: 0.3rem;
`;

const Value = styled.div`
  h3 {
    color: ${theme.color.base1};
    color: ${theme.color.font};
    font-size: 28px;
    font-weight: 500;
  }
`;

const SliderClickableArea = styled.div`
  position: absolute;
  height: 20px;
  width: 100%;
  cursor: pointer;
`;

const Cos2 = styled.div`
  position: absolute;
`;

export default function Slider(props) {
  let min = props.min || 0;
  let max = props.max || 100;
  let step = props.step || 1;

  // Initialize the value state considering the min prop
  let [value, setValue] = useState(props.value || min);

  useEffect(() => {
    // Ensure internal state reflects prop changes
    setValue(props.value !== undefined ? props.value : min);
  }, [props.value, min]);

  let percent = (value - min) / (max - min);

  let [dragging, setDragging] = useState(false);
  let constraintsRef = useRef();
  let handleRef = useRef();
  let progressBarRef = useRef();
  let handleSize = 24;
  let handleX = useMotionValue(0);
  let progress = useTransform(handleX, (v) => v + handleSize / 2);
  let background = useMotionTemplate`linear-gradient(90deg, #95C11F ${progress}px, #dddddd 0)`;

  function handleDrag() {
    let handleBounds = handleRef.current.getBoundingClientRect();
    let middleOfHandle = handleBounds.x + handleBounds.width / 2;
    let progressBarBounds = progressBarRef.current.getBoundingClientRect();
    let newProgress =
      (middleOfHandle - progressBarBounds.x) / progressBarBounds.width;
    let newValue = roundToStep(min + newProgress * (max - min), step);
    setValue(newValue);
    props.onChange &&
      DebounceService.getInstance().setValueWithDebounce(
        props.onChange,
        newValue
      );
  }

  useEffect(() => {
    let newProgress = (value - min) / (max - min);
    let progressBarBounds = progressBarRef.current.getBoundingClientRect();
    handleX.set(newProgress * progressBarBounds.width);
  }, [handleX, max, min, value]);

  function roundToStep(value, step) {
    return Math.round(value / step) * step;
  }

  return (
    <SliderWrapper>
      <LabelWrapper>
        <CircIconLabeled name={props.icon} label={props.label} />
        <Value>
          <motion.h3 animate={{ y: dragging && percent < 0.15 ? 5 : 0 }}>
            {Math.floor(value * 10) / 10}
            {props.unit || "°C"}
          </motion.h3>
        </Value>
      </LabelWrapper>

      <SliderBarWrapper>
        <p>
          {min} {props.unit || "°C"}
        </p>

        <SliderBar data-test="slider">
          <motion.div
            className="bar"
            data-test="slider-background"
            style={{ background }}
          />

          <Cos2
            data-test="slider-progress"
            ref={progressBarRef}
            style={{
              left: handleSize / 2,
              right: handleSize / 2,
            }}
          />

          <Knob ref={constraintsRef}>
            <motion.div
              data-test="slider-handle"
              ref={handleRef}
              drag="x"
              dragMomentum={false}
              dragConstraints={constraintsRef}
              dragElastic={0}
              onDrag={handleDrag}
              onDragStart={() => setDragging(true)}
              onDragEnd={() => setDragging(false)}
              onPointerDown={() => setDragging(true)}
              onPointerUp={() => setDragging(false)}
              animate={{
                scale: dragging ? 2 : 1,
              }}
              style={{
                width: handleSize,
                height: handleSize,
                x: handleX,
                y: "-8px",
              }}
            />
          </Knob>

          <SliderClickableArea
            data-test="slider-clickable-area"
            onPointerDown={(event) => {
              let { left, width } =
                progressBarRef.current.getBoundingClientRect();
              let position = event.pageX - left;
              let newProgress = clamp(position / width, 0, 1);
              let newValue = roundToStep(min + newProgress * (max - min), step);
              setValue(newValue);
              props.onChange &&
                DebounceService.getInstance().setValueWithDebounce(
                  props.onChange,
                  newValue
                );
              animate(handleX, newProgress * width);
            }}
          />
        </SliderBar>

        <p>
          {max} {props.unit || "°C"}
        </p>
      </SliderBarWrapper>
    </SliderWrapper>
  );
}

function clamp(number, min, max) {
  return Math.max(min, Math.min(number, max));
}
