import React, { useState, useEffect } from "react";

//STYLES
import styled from "styled-components";

//COMPONENTS
import Harmonogram_settings from "./harmonogram/HarmonogramSettings";
import { Heading } from "../../../../utils/utilsstyles";
import Button from "../../../Button/Button";
import ButtonRounded from "../../../Button/ButtonRounded";
import Modal from "../../../Modals/Modal";
import CustomCheckbox from "../../../Forms/checkbox2";

//FUNCTIONS
import {
  saveProgram,
  copyProgramToDays,
  copyProgramToDevices,
  getSameTypeDevices,
} from "../../../../services/ApiService";
import { useLanguage } from "../../../../services/LanguageContext";

//STYLES
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  h3 {
    margin-bottom: 5px;
  }
`;

const CoppySettingsWrapper = styled.div`
  display: grid;
  align-content: end;
  height: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
`;

const ButtonsSubmitWrapper = styled.div`
  display: grid;
  row-gap: 1rem;
  margin-top: 2rem;
  justify-content: stretch;
  justify-items: stretch;
`;

//App

function HarmonogramMode({ device }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDeviceOpen, setIsModalDeviceOpen] = useState(false);
  const [isSavingProgram, setIsSavingProgram] = useState(false);
  const [activeDay, setActiveDay] = useState("Monday");
  const [copyListOfActiveDays, setCopyListOfActiveDays] = useState([]);
  const [segments, setSegments] = useState(
    device.program.days[activeDay]?.segments || []
  );
  const [sameTypeDevices, setSameTypeDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [isAnyDeviceSelected, setIsAnyDeviceSelected] = useState(false);

  const toggleModal = () => {
    setCopyListOfActiveDays([]);
    setIsModalOpen(!isModalOpen);
  };

  const toggleModalDevice = async () => {
    if (!isModalDeviceOpen) {
      const devices = await getSameTypeDevices(device.id);
      setSameTypeDevices(devices);
      setSelectedDevices([]);
      setIsAnyDeviceSelected(false);
    }
    setIsModalDeviceOpen(!isModalDeviceOpen);
  };

  const toggleChecked = (deviceId) => {
    setSelectedDevices((prevSelectedDevices) => {
      if (prevSelectedDevices.includes(deviceId)) {
        // If the device is already selected, remove it
        setIsAnyDeviceSelected(
          prevSelectedDevices.filter((id) => id !== deviceId).length > 0
        );
        return prevSelectedDevices.filter((id) => id !== deviceId);
      } else {
        // Otherwise, add it to the selection
        setIsAnyDeviceSelected(true);
        return [...prevSelectedDevices, deviceId];
      }
    });

    setIsAnyDeviceSelected(selectedDevices.length > 0);
  };

  const handleCopyToDevices = async () => {
    await copyProgramToDevices(device.id, device.program, selectedDevices);

    toggleModalDevice();
  };

  const handleDayClick = (day) => {
    setActiveDay(day);
  };

  const { translate } = useLanguage();

  // Function to update the local segments when activeDay changes
  const updateSegments = () => {
    const newSegments = device.program.days[activeDay]?.segments || [];
    setSegments(newSegments);
  };

  // Call updateSegments whenever activeDay changes
  useEffect(() => {
    updateSegments();
  }, [activeDay]);

  // Effect to refresh segments if device.program.days or device changes
  useEffect(() => {
    // Update segments and reset the active day if necessary
    updateSegments();
  }, [device]);

  console.log("Active Day:", activeDay);
  console.log("Day Hours:", device.program.days[activeDay]);

  const handleSegmentChange = (index) => (newSegment) => {
    const updatedSegments = [...segments];
    updatedSegments[index] = newSegment;

    const updatedProgram = {
      ...device.program,
      days: {
        ...device.program.days,
        [activeDay]: {
          ...device.program.days[activeDay],
          segments: updatedSegments,
        },
      },
    };

    setSegments(updatedSegments);
    console.log(updatedProgram);
  };

  const onSaveProgramClick = async () => {
    setIsSavingProgram(true);

    try {
      await saveProgram(device.id, device.program);
      // You can add any further success handling logic here if needed
    } catch (error) {
      console.error("An error occurred while saving the program:", error);
      // Optionally, handle the error e.g., by displaying a message to the user
    } finally {
      setIsSavingProgram(false);
    }
  };

  const handleCopyDayClick = (dayName) => {
    setCopyListOfActiveDays((prevDays) => {
      if (prevDays.includes(dayName)) {
        // If the day is already active, remove it from the list
        return prevDays.filter((day) => day !== dayName);
      } else {
        // If the day is not active, add it to the list
        return [...prevDays, dayName];
      }
    });
  };

  const handleCopyDays = async () => {
    await copyProgramToDays(
      device.id,
      device.program,
      activeDay,
      copyListOfActiveDays
    );

    toggleModal();
  };

  const getMinTime = (index) => {
    let hour = 0;
    let minute = 0;

    if (index === 0) {
      return convertToTimeString(hour, minute);
    }

    const previousSegment = segments[index - 1];

    return convertToTimeString(previousSegment.hour, previousSegment.minute);
  };

  const getMaxTime = (index) => {
    let hour = 23;
    let minute = 59;

    if (index === 5) {
      return convertToTimeString(hour, minute);
    }

    const nextSegment = segments[index + 1];

    return convertToTimeString(nextSegment.hour, nextSegment.minute);
  };

  const convertToTimeString = (hour, minute) => {
    const referenceDate = new Date(1970, 0, 1); // You can use any date here
    referenceDate.setHours(hour, minute, 0);
    return referenceDate.toISOString();
  };

  return (
    <Wrapper>
      <Heading>
        <h3>{translate("week_plan")}</h3>
      </Heading>

      <ButtonsWrapper>
        {Object.values(device.program.days).map((day) => (
          <ButtonRounded
            key={day.name} // use the unique name as the key
            text={translate(day.name)} // use day.name if you need it translated
            active={activeDay === day.name}
            onClick={() => handleDayClick(day.name)} // handle click events with the day name
          />
        ))}
      </ButtonsWrapper>

      {segments.map((segment, index) => (
        <Harmonogram_settings
          initialSegment={segment}
          device={device}
          icon={`segment_${index + 1}`}
          key={`${activeDay}-${index}`} // unique key for each segment
          name={translate(`segment_${index + 1}`)} // constructing a name if needed
          start_hour_hour={segment.hour}
          start_hour_minute={segment.minute}
          start_hour={`Hour: ${segment.hour}, Minute: ${segment.minute}`} // combining hour and minute
          onSegmentChange={handleSegmentChange(index)} // Pass index to the handler
          minTime={getMinTime(index)}
          maxTime={getMaxTime(index)}
          temp_set={`Temp. ${device
            .convertToTempScale(segment.temperature)
            .toFixed(1)}`} // displaying temperature
        />
      ))}
      <CoppySettingsWrapper>
        {isModalOpen && (
          <Modal
            title={translate("copy_settings_another_day")}
            intro={translate("intro_choose_coppy_days")}
            onClose={toggleModal}
            onConfirm={() => handleCopyDays()}
          >
            <ButtonsWrapper>
              {Object.values(device.program.days).map((day, index) => (
                <ButtonRounded
                  key={day.name}
                  text={translate(day.name)}
                  active={
                    activeDay === day.name ||
                    copyListOfActiveDays.includes(day.name)
                  }
                  onClick={() => handleCopyDayClick(day.name)}
                />
              ))}
            </ButtonsWrapper>
          </Modal>
        )}
      </CoppySettingsWrapper>
      {isModalDeviceOpen && (
        <Modal
          title={translate("copy_settings_another_device")}
          intro={translate("intro_choose_coppy_device")}
          onClose={toggleModalDevice}
          isValid={isAnyDeviceSelected}
          onConfirm={() => handleCopyToDevices()}
        >
          {sameTypeDevices.map((item) => (
            <CustomCheckbox
              key={item.id}
              label_text={item.name}
              checked={selectedDevices.includes(item.id)}
              onChange={() => toggleChecked(item.id)}
            />
          ))}
        </Modal>
      )}
      <ButtonsSubmitWrapper>
        <Button
          className={isSavingProgram ? "loading" : ""}
          onClick={onSaveProgramClick}
        >
          {translate("save_harmonograms")}
        </Button>

        <Button variant="white" onClick={toggleModal}>
          {translate("copy_settings_another_day")}
        </Button>

        <Button variant="white" onClick={toggleModalDevice}>
          {translate("copy_settings_another_device")}
        </Button>
      </ButtonsSubmitWrapper>
    </Wrapper>
  );
}

export default HarmonogramMode;
