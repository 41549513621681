import React, { useState, useEffect, useRef } from "react";
// STYLES
import styled from "styled-components";
// DATA
import { devices } from "../../data/data";
import search from "./../../assets/icons/search.png";
import sort from "./../../assets/icons/sort.png";
// COMPONENTS
import Input from "../../components/Forms/Input";
import Dropdown from "../../components/Forms/Dropdown";
import { useLanguage } from "../../services/LanguageContext";

import { DeviceTypeEnum } from "../../utils/Enums/DeviceTypeEnum";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
  margin-bottom: 1rem;
  > div {
    display: grid;
    grid-template-columns: 30px 1fr;
    column-gap: 5px;
    align-items: center;
  }

  img {
    opacity: 0.2;
    height: 24px;
    margin-top: 1rem;
  }
`;

// Function to map rooms to dropOptions format
const mapRoomsToDropOptions = (rooms) => {
  return rooms.map((room) => ({
    value: room.room_Id,
    label: room.name,
  }));
};

const mapHomesToDropOptions = (homes) => {
  return homes.map((home) => ({
    value: home.homeId,
    label: home.name,
  }));
};

const models = devices.map((device) => {
  return device.models;
});

function SearchFilterPanel({
  onFilterChanged,
  homes,
  selectedHome,
  // Include other props if needed
}) {
  // const firstHome = homes.length > 0 ? homes[0] : null;
  const [filter, setFilter] = useState({
    home: null,
    rooms: [],
    name: "",
    deviceTypes: [],
    productIds: [],
    isOnline: null,
  });
  const [rooms, setRooms] = useState([]);
  const { translate } = useLanguage();

  const dropdownRef = useRef(null);

  useEffect(() => {
    const savedFilter = localStorage.getItem("filterState");
    if (savedFilter) {
      const parsedFilter = JSON.parse(savedFilter);
      setFilter(parsedFilter);

      // Set rooms if a home is already selected in the saved filter
      if (parsedFilter.home) {
        const selectedHomeData = homes.find(
          (home) => home.homeId === parseInt(parsedFilter.home)
        );
        if (selectedHomeData) {
          setRooms(selectedHomeData.rooms);
        }
      }
    }
  }, [homes]);

  useEffect(() => {
    // Update local storage when filter changes
    if (filter.home !== null) {
      localStorage.setItem("filterState", JSON.stringify(filter));
      onFilterChanged(filter);
    }
  }, [filter, onFilterChanged]);

  const onNameFilterChangedCallback = (selectedName) => {
    setFilter((prevFilter) => ({ ...prevFilter, name: selectedName }));
  };

  const onHomeFilterChangedCallback = (selectedHome) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      home: selectedHome.value,
      rooms: [],
    }));

    const homeId = parseInt(selectedHome.value);
    const selectedHomeData = homes.find((home) => home.homeId === homeId);

    if (selectedHomeData) {
      setRooms(selectedHomeData.rooms);
    } else {
      console.log("Home not found.");
    }

    handleClearSelection();
  };

  const onRoomsFilterChangedCallback = (selectedRooms) => {
    const roomIds = selectedRooms.map((room) => parseInt(room.value));
    setFilter((prevFilter) => ({ ...prevFilter, rooms: roomIds }));
  };

  // Function to get the default home based on filter state
  const getDefaultHome = () => {
    const homeId = filter.home;
    return homes.find((home) => home.homeId === homeId)
      ? {
          value: homeId,
          label: homes.find((home) => home.homeId === homeId).name,
        }
      : null; // Return matched home or null if not found
  };

  // Function to get the default rooms based on filter state
  const getDefaultRooms = () => {
    const allOptions = mapRoomsToDropOptions(rooms);
    return allOptions.filter((option) => filter.rooms.includes(option.value));
  };

  // Function to get the default name for the input field
  const getDefaultName = () => {
    return filter.name || ""; // Return the name from the filter state, or an empty string if not set
  };

  // Function to filter default device types based on selected device types from filter
  const getDefaultDeviceTypes = () => {
    const allOptions = mapDeviceTypesToDropOptions(devices);
    return allOptions.filter((option) =>
      filter.deviceTypes.includes(option.value)
    );
  };

  // Function to filter default values based on selected product IDs
  const getDefaultProductIds = () => {
    const allOptions = mapProductIdsToDropOptions();
    return allOptions.filter((option) =>
      filter.productIds.includes(option.value[0])
    );
  };

  // Function to get the default isOnline status based on filter state
  const getDefaultIsOnline = () => {
    const allOptions = mapIsOnlineToDropDownOptions();
    return allOptions.find((option) => option.value === filter.isOnline);
  };

  const mapDeviceTypesToDropOptions = () => {
    return [
      {
        value: DeviceTypeEnum.EngoThermostat,
        label: translate("deviceThermostat"),
      },
      {
        value: DeviceTypeEnum.EngoRelay,
        label: translate("deviceRelay"),
      },
      {
        value: DeviceTypeEnum.EngoUnderfloor,
        label: translate("deviceUnderfloor"),
      },
      {
        value: DeviceTypeEnum.EngoZigbeeGateway,
        label: translate("deviceOther"),
      },
    ];
  };

  const mapProductIdsToDropOptions = () => {
    return [
      {
        value: ["zrk5ctgzhyaupkrn"],
        label: "E10",
      },
      {
        value: ["iczncfr5vuou25x9"],
        label: "E10 Modbus",
      },
      {
        value: ["cmyc8g5i", "cg8hdnjv"],
        label: "E25",
      },
      {
        value: ["7frvwc6y3rh903az"],
        label: "E55",
      },
      {
        value: ["bowuem6d9ihzwymj"],
        label: "E901",
      },
      {
        value: ["gtouvmvl", "awnadkan"],
        label: "EONE",
      },
      {
        value: ["raa0m9dr"],
        label: "EMODZB",
      },
      {
        value: ["pbcp9vqc"],
        label: "WBREL1ZB12A",
      },
      {
        value: ["sfjr4fnz"],
        label: "EREL-16",
      },
      {
        value: ["zaabefnt"],
        label: "ECB62ZB",
      },
    ];
  };

  const mapIsOnlineToDropDownOptions = () => {
    return [
      {
        value: true,
        label: translate("deviceOnline"),
      },
      {
        value: false,
        label: translate("deviceOffline"),
      },
    ];
  };

  const onDeviceFilterChangedCallback = (selectedDevice) => {
    const deviceIds = selectedDevice.map((device) => parseInt(device.value));
    setFilter((prevFilter) => ({ ...prevFilter, deviceTypes: deviceIds }));

    // setFilterDevice(deviceIds);
    onFilterChanged(filter);
  };

  const onProductIdsFilterChangedCallback = (selectedProductIds) => {
    const productIds = selectedProductIds.map(
      (deviceModel) => deviceModel.value
    );
    const flatProductIds = productIds.flat();
    setFilter((prevFilter) => ({ ...prevFilter, productIds: flatProductIds }));

    // setFilterDeviceModel(productIds);
    onFilterChanged(filter);
  };

  const onOnlineOfflineFilterChangedCallback = (selectedOnlineOffline) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      isOnline: selectedOnlineOffline?.value,
    }));

    // setFilterDeviceModel(productIds);
    onFilterChanged(filter);
  };

  const handleClearSelection = () => {
    // Call the clearSelection function from the Dropdown component
    // if (dropdownRef.current && dropdownRef.current.clearSelection) {
    //   dropdownRef.current.clearSelection();
    // }
  };

  useEffect(() => {
    if (selectedHome) {
      setFilter((prevFilter) => {
        const updatedFilter = { ...prevFilter, home: selectedHome.homeId };

        onFilterChanged(updatedFilter);
        return updatedFilter;
      });

      setRooms(selectedHome.rooms);
    }
  }, [selectedHome]);

  useEffect(() => {
    onFilterChanged(filter);
  }, [filter, onFilterChanged]);

  return (
    <>
      <Wrapper>
        <div>
          <img alt="filter" src={sort} />
          <Dropdown
            dropdown={mapHomesToDropOptions(homes)}
            handleChange={onHomeFilterChangedCallback}
            label_text={translate("selectedHouse") || "Wybrany dom"}
            isMulti={false}
            isClearable={false}
            placeholder={
              getDefaultHome() ? getDefaultHome().name : "Wybierz dom"
            }
            defaultValue={getDefaultHome()}
          />
        </div>

        <div>
          <img alt="sort" src={sort} />
          <Dropdown
            ref={dropdownRef}
            dropdown={mapRoomsToDropOptions(rooms)}
            isMulti
            handleChange={onRoomsFilterChangedCallback}
            label_text={translate(
              "searchByRoom",
              "Pokaż urządzenia w pomieszczeniach"
            )}
            placeholder={translate(
              "searchByRoomSelectRoom",
              "Wszystkie pokoje"
            )}
            handleClear={handleClearSelection}
            defaultValue={getDefaultRooms()}
          />
        </div>
        <div>
          <img alt="wyszukaj" src={search} />
          <Input
            label_for="name"
            label_text={translate("searchByName", "Wyszukaj po nazwie lub ID")}
            type="text"
            id="name"
            name="name"
            onChange={(event) =>
              onNameFilterChangedCallback(event.target.value)
            }
            maxlength="40"
            value={getDefaultName()}
          />
        </div>
      </Wrapper>
      {
        //druga linia filtrów
      }
      <Wrapper>
        <div>
          <img alt="filter" src={sort} />
          <Dropdown
            dropdown={mapDeviceTypesToDropOptions(devices)}
            handleChange={onDeviceFilterChangedCallback}
            label_text={translate("device_type")}
            isMulti
            placeholder={translate("choose_device")}
            defaultValue={getDefaultDeviceTypes()}
          />
        </div>

        <div>
          <img alt="sort" src={sort} />
          <Dropdown
            dropdown={mapProductIdsToDropOptions()}
            isMulti
            handleChange={onProductIdsFilterChangedCallback}
            label_text={translate("device_model") || "Wybrany dom"}
            placeholder={translate("choose_model")}
            handleClear={handleClearSelection}
            defaultValue={getDefaultProductIds()}
          />
        </div>
        <div>
          <img alt="sort" src={sort} />
          <Dropdown
            ref={dropdownRef}
            dropdown={mapIsOnlineToDropDownOptions()}
            isMulti={false}
            isClearable={true}
            handleChange={onOnlineOfflineFilterChangedCallback}
            label_text={translate("device_status")}
            placeholder={translate("choose_status")}
            handleClear={handleClearSelection}
            defaultValue={getDefaultIsOnline()}
          />
        </div>
      </Wrapper>
    </>
  );
}

export default SearchFilterPanel;
