import React from "react";
// STYLES
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
// COMPONENTS
import { Ikonka } from "../../../Icons/Icon";
import { Tag } from "../../../../utils/utilsstyles";
import CircIconLabeled from "../../../Icons/CircIconLabeled";

const Infoline = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: stretch;
  //background-color: red;
  height: 85px;

  > div {
    display: flex;
  }

  svg {
    fill: ${theme.color.base1};
    height: 40px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      fill: ${theme.color.prim1};
    }
  }

  a.off {
    svg {
      fill: ${theme.color.sec5};

      &:hover {
        fill: ${theme.color.prim1};
      }
    }
  }
`;

const TempGrid = styled.div`
  display: grid;
  column-gap: 1.9rem;

  &.height0 {
    height: 0;
  }

  &.margintop {
    margin-top: 0.5rem;
  }
`;

const Info = styled.div`
  display: flex;

  //background-color: antiquewhite;

  &.marginleft {
    margin-left: 13px;
  }

  svg {
    align-self: center;
    fill: ${theme.color.sec1};
    height: 24px;
  }

  p {
    margin-left: 0.2rem;
    font-size: 24px;
    font-weight: 500;
    align-self: center;
    transform: translateY(-1px);
  }
`;

function InfoLine({
  temperatureset,
  temperature,
  onClick,
  status,
  rooms,
  isOnline,
}) {
  return (
    <Infoline>
      {/* {rooms && rooms.length >= 1 && (
        <TempGrid>
          {rooms.map((room) => (
            <Tag key={room.name}>
              <p>{room.name}</p>
            </Tag>
          ))}
        </TempGrid>
      )} */}

      {isOnline && (
        <>
          <TempGrid className={rooms && rooms.length >= 1 ? "" : "margintop"}>
            {rooms &&
              rooms.length >= 1 &&
              rooms.map((room) => (
                <Tag key={room.name}>
                  <p>{room.name}</p>
                </Tag>
              ))}
            {temperatureset && (
              <Info className={rooms && rooms.length >= 1 ? "" : "marginleft"}>
                <Ikonka name="temperatureset" />
                <p>{temperatureset}°C</p>
              </Info>
            )}
            {temperature && (
              <Info>
                <Ikonka name="temperature" />
                <p>{temperature}°C</p>
              </Info>
            )}
          </TempGrid>
          {temperature && (
            <CircIconLabeled name="flame" label="status urządzenia" />
          )}
        </>
      )}

      {/* {isOnline && onClick && (
        <a onClick={onClick} className={status ? "" : "off"}>
          <Ikonka name="powerbutton" />
        </a>
      )} */}
    </Infoline>
  );
}

export default InfoLine;
