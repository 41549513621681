import { executeNumberChangeCommand, setOnDevice } from "./ApiService";
import { debounce } from "lodash";

class TemperatureSetService {
  /**
   * Initializes a new instance of the TemperatureSetService class.
   */
  constructor() {
    if (TemperatureSetService.instance) {
      return TemperatureSetService.instance;
    }

    TemperatureSetService.instance = this;

    // Throttle the setTemperature method to only allow one call every 200 milliseconds
    this.setTemperature = debounce(this.setTemperature.bind(this), 200, {
      trailing: true,
    });

    this.setHolidayTemperature = debounce(
      this.setHolidayTemperature.bind(this),
      200,
      {
        trailing: true,
      }
    );
  }

  /**
   * Retrieves the single instance of the TemperatureSetService class.
   * If an instance does not exist, it creates a new one.
   * @returns {TemperatureSetService} The single instance of the TemperatureSetService class.
   */
  static getInstance() {
    if (!TemperatureSetService.instance) {
      TemperatureSetService.instance = new TemperatureSetService();
    }

    return TemperatureSetService.instance;
  }

  /**
   * Sets the temperature by executing a number change command.
   * @param {Object} data - The data to set the temperature.
   */
  setTemperature(data) {
    console.log("Temperature set service ");
    console.log(data);

    setOnDevice(data)
      .then((response) => {
        console.log("Data posted successfully");
        // setTempSet(temperature);
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  }

  setHolidayTemperature(data) {
    console.log("Temperature set service setHolidayTemperature");
    console.log(data);

    setOnDevice(data)
      .then((response) => {
        console.log("Data posted successfully");
        // setTempSet(temperature);
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  }
}

export default TemperatureSetService;
