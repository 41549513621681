import React, { useEffect, useState } from "react";
//DATA
import axios from "axios";
//STYLES
import styled from "styled-components";
import { theme } from "../../../utils/theme";
//DATA
//COMPONENTS
import CardWrapper from "./CardElements/CardWrapper";
import CardHeader from "./CardElements/CardHeader";
import InfoLine from "./CardElements/InfoLine";
import CardFastSettingsWrapper from "./CardElements/CardFastSettingsWrapper";
import TemperatureSetService from "../../../services/TemperatureSetService";
import { FullCardWrapper, Heading, Loader } from "../../../utils/utilsstyles";

import CircSlider from "../../Slider/circSlider/circSlider";
import _ from "lodash";
import CircIconLabeled from "../../Icons/CircIconLabeled";
import { recalculateTemperature } from "../../../services/TemperatureRecalculationService";

import { useLanguage } from "../../../services/LanguageContext";
import CardOnOffButton from "./CardElements/CardOnOffButton";
import { loadDeviceDetails } from "../../../services/ApiService";
import Device from "../../../services/Device";
import { MutatingDots } from "react-loader-spinner";

export const TermostatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  h6 {
    font-size: 70px;
    text-align: center;
    color: ${theme.color.base1};
    font-weight: 400;
    line-height: 70px;
    margin: 1rem auto;
  }

  p {
    color: ${theme.color.sec4};
    text-align: center;
    font-size: 18px;
  }
`;

const MoreInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 5px;
  justify-items: stretch;
  align-items: center;
`;

const checkTemp = (device, propertyToCheck) => {
  let tempSet = 22;

  for (const item of device.statusList) {
    if (item.code === propertyToCheck) {
      tempSet = item.value;
      break;
    }
  }

  // console.log(device.name + ", status:  " + switch1Value);
  return tempSet;
};

function ThermostatCard({
  deviceId,
  device,
  name,
  type,
  typeId,
  rooms,
  isOnline,
}) {
  const { translate } = useLanguage();
  const [loading, setLoading] = useState(true);
  const [tempSet, setTempSet] = useState(23);
  const [tempCurrent, setTempCurrent] = useState(22);
  const [currentDevice, setCurrentDevice] = useState(device); // State for device

  const _engoThermostatProductId = [
    "zrk5ctgzhyaupkrn",
    "awnadkan",
    "cg8hdnjv",
    "gtouvmvl",
    "iczncfr5vuou25x9",
    "bowuem6d9ihzwymj",
    "cmyc8g5i",
    "f1tbiilmxdkvy9x2",
    "7frvwc6y3rh903az",
  ];

  // Load device from API when the component opens
  const loadDeviceFromAPI = async () => {
    const fetchedDeviceDetails = await loadDeviceDetails(device.id); // Use the id from the URL
    console.log("Thermostat details", fetchedDeviceDetails);
    setCurrentDevice(new Device(fetchedDeviceDetails));
  };

  useEffect(() => {
    console.log("Device prop updated");
    setCurrentDevice(device); // Update the device state when the prop changes
  }, [device]);

  useEffect(() => {
    console.log("Aktualizacaja useEffect");
    // setTempSet(checkTemp(currentDevice, "temp_set"));
    setTempSet(currentDevice.getStatusValueInTempScale("temp_set"));
    setTempCurrent(checkTemp(currentDevice, "temp_current"));
  }, [currentDevice]);

  // Call loadDeviceFromAPI on opening
  const handleOpening = async () => {
    await loadDeviceFromAPI();
  };

  const handleArrowUpClick = () => {
    handleTempChange(positiveIncrement);
  };

  const handleArrowDownClick = () => {
    handleTempChange(negativeIncrement);
  };

  const handleTempChange = (isNegative) => {
    let tempIncrement = device.getStepRangeInTempScale("temp_set");
    let sign = isNegative ? -1 : 1;
    tempIncrement = tempIncrement * sign;
    let newTempSet = tempSet + tempIncrement;

    sendTemperatureUpdate(newTempSet);
    setTempSet(newTempSet);

    console.log("Component clicked! " + deviceId);
  };

  const sendTemperatureUpdate = (temperature) => {
    const adjustedTemperature = _engoThermostatProductId.includes(
      currentDevice.productId
    )
      ? temperature * 10
      : temperature;

    const roundedTemperature = Math.round(adjustedTemperature);

    const data = {
      deviceId: deviceId,
      command: "temp_set",
      value: parseInt(roundedTemperature),
    };

    const temperatureSetService = TemperatureSetService.getInstance();
    temperatureSetService.setTemperature(data);
  };

  // const debouncedHandleOnChanged = _.debounce((temperature) => {
  //   sendTemperatureUpdate(temperature);
  // }, 1000);

  const handleOnChanged = (temperature) => {
    setTempSet(temperature);
    // debouncedHandleOnChanged(temperature);
  };

  const handleOnChangedDebounce = (temperature) => {
    currentDevice.setOnDeviceByParamsInTempScale("temp_set", temperature);
  };

  const handleOnOffThermostatClick = () => {
    const propertyName = currentDevice.findExistingPropertyFromList([
      "switch",
      "switch2",
    ]);

    if (propertyName === "") {
      return;
    }

    if (currentDevice.getStatusValue(propertyName)) {
      currentDevice.setOnDeviceByParams(propertyName, false);
    } else {
      currentDevice.setOnDeviceByParams(propertyName, true);
    }
  };

  const negativeIncrement = true;
  const positiveIncrement = false;

  return (
    <CardWrapper
      isOnline={isOnline}
      onOpening={async () => {
        setLoading(true);
        await handleOpening();
        setLoading(false);
      }}
      settings={
        <CardFastSettingsWrapper>
          {loading ? (
            <Loader>
              <MutatingDots
                visible={true}
                height="100"
                width="100"
                color={theme.color.base1}
                secondaryColor={theme.color.base1}
                radius="12.5"
                ariaLabel="mutating-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </Loader>
          ) : (
            <>
              <MoreInfoWrapper>
                <CircIconLabeled
                  name="temperature"
                  label={translate(
                    "thermostatCurrentTemperature",
                    "Temp. pomieszczenia"
                  )}
                  value={`${recalculateTemperature(
                    checkTemp(device, "temp_current"),
                    device.productId
                  )}°C`}
                />

                {currentDevice.checkIfPropertyExists("work_state") && (
                  <CircIconLabeled
                    name="overview"
                    label={translate("thermostatStatus", "Status")}
                    value={translate(
                      "work_state_" + currentDevice.getStatusValue("work_state")
                    )}
                  />
                )}

                {currentDevice.checkIfPropertyExists("run_mode") && (
                  <CircIconLabeled
                    name="harmonogram"
                    label={translate("thermostatRunMode", "Tryb pracy")}
                    value={translate(
                      "run_mode_" + currentDevice.getStatusValue("run_mode")
                    )}
                  />
                )}

                {currentDevice.checkIfPropertyExists("battery_percentage") && (
                  <CircIconLabeled
                    name="battery"
                    label={translate("thermostatBatteryStatus", "Stan baterii")}
                    value={`${currentDevice.getStatusValue(
                      "battery_percentage"
                    )} %`}
                  />
                )}

                {currentDevice.checkIfPropertyExists("humidity") && (
                  <CircIconLabeled
                    name="hydro"
                    label={translate("thermostatHumidity", "Wilgotność")}
                    value={`${currentDevice.getStatusValue("humidity")} %`}
                  />
                )}
              </MoreInfoWrapper>

              <CircSlider
                buttonPluse={handleArrowUpClick}
                buttonMinus={handleArrowDownClick}
                temperatureset={tempSet}
                onChanged={handleOnChanged}
                onChangedDebounce={handleOnChangedDebounce}
                minTemperature={currentDevice.getFromRangeInTempScale(
                  "temp_set"
                )}
                maxTemperature={currentDevice.getToRangeInTempScale("temp_set")}
              />
            </>
          )}
        </CardFastSettingsWrapper>
      }
    >
      <CardHeader
        name={name}
        type={type}
        typeId={typeId}
        deviceId={deviceId}
        onCliClick="openSettings"
        device={device}
        isOnline={isOnline}
      />

      <InfoLine
        rooms={rooms}
        temperature={recalculateTemperature(
          checkTemp(device, "temp_current"),
          device.productId
        )}
        temperatureset={tempSet.toFixed(1)}
        // OnClick=""
        isOnline={isOnline}
      />
      {isOnline &&
        currentDevice.findExistingPropertyFromList(["switch", "switch2"]) !==
          "" && (
          <CardOnOffButton
            onClick={handleOnOffThermostatClick}
            status={currentDevice.getStatusValueFromProperties([
              "switch",
              "switch2",
            ])}
          />
        )}
    </CardWrapper>
  );
}

export default ThermostatCard;
