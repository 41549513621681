import { HubConnectionBuilder } from "@microsoft/signalr";

const SERVER_BASE_URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL_LOCAL
    : process.env.REACT_APP_API_URL_PRODUCTION;

const HUB_BASE_URL = SERVER_BASE_URL + "/realtime-updates-hub";

class SignalRService {
  constructor() {
    if (!SignalRService.instance) {
      this.connection = new HubConnectionBuilder()
        .withUrl(HUB_BASE_URL) // Replace with your SignalR server URL
        .withAutomaticReconnect()
        .build();

      this.subscribers = {};

      this.connection
        .start()
        .then(() => {
          console.log("SignalR Connected.");
          this.connection.on("UpdateDeviceStatus", (device) => {
            this.notifySubscribers("UpdateDeviceStatus", device);
          });
        })
        .catch((err) => console.log("SignalR Connection Error: ", err));

      SignalRService.instance = this;
    }

    return SignalRService.instance;
  }

  subscribe(event, callback) {
    if (!this.subscribers[event]) {
      this.subscribers[event] = [];
    }
    this.subscribers[event].push(callback);
  }

  unsubscribe(event, callback) {
    if (!this.subscribers[event]) return;

    this.subscribers[event] = this.subscribers[event].filter(
      (subscriber) => subscriber !== callback
    );

    if (this.subscribers[event].length === 0) {
      delete this.subscribers[event];
    }
  }

  notifySubscribers(event, data) {
    if (!this.subscribers[event]) return;
    this.subscribers[event].forEach((callback) => callback(data));
  }
}

const instance = new SignalRService();
Object.freeze(instance);

export default instance;
