import React, { useState, useEffect } from "react";
//STYLES
import styled from "styled-components";
//Utils
import { useLanguage } from "../../../../services/LanguageContext";
import TimeService from "../../../../services/TimeService";
import { setRelayAutoOff } from "../../../../services/ApiService";
//COMPONENTS
import { Break, Heading } from "../../../../utils/utilsstyles";
import Switch from "../../../Switch/Switch";
import Timer from "../../../Forms/Timer";

//Styles
const Wrapper = styled.div`
  h3 {
    margin-bottom: 5px;
  }
`;

const Period = styled.div`
  margin-bottom: 1rem;
  p.valid {
    color: red;
    font-weight: 500;
  }
`;

//App
function AutoOffMode({ device }) {
  const { translate } = useLanguage();

  const [isOn, setIsOn] = useState(false);
  const [selectedMinute, setSelectedMinute] = useState(0);
  const [selectedSecond, setSelectedSecond] = useState(0);
  const [isTimeValid, setIsTimeValid] = useState(true);

  useEffect(() => {
    if (
      device &&
      device.deviceConfig &&
      device.deviceConfig.switchInchingConfig
    ) {
      // Fetch time and isOn configurations safely
      const minute =
        device.deviceConfig?.switchInchingConfig?.time?.minute ?? 0;
      const second =
        device.deviceConfig?.switchInchingConfig?.time?.second ?? 0;
      const isSwitchOn =
        device.deviceConfig?.switchInchingConfig?.isOn || false;

      setSelectedMinute(minute);
      setSelectedSecond(second);
      setIsOn(isSwitchOn);
    }
  }, [device]);

  const timeChangedHandler = (minute, second, isValid) => {
    setSelectedMinute(minute);
    setSelectedSecond(second);
    setIsTimeValid(isValid);
  };

  const handleSwitchToggle = () => {
    if (isTimeValid) {
      const newIsOnState = !isOn;
      setRelayAutoOff(device.id, selectedMinute, selectedSecond, newIsOnState);
    }
  };

  return (
    <Wrapper>
      <Heading>
        <h3>{translate("auto_off")}</h3>
        <p>{translate("auto_off_info")}</p>
      </Heading>
      <Break height="3rem" />
      <Switch
        label={translate("auto_off_on")}
        labelOff={translate("auto_off_off")}
        isOn={isOn}
        onChange={handleSwitchToggle}
      />
      <Break height="2rem" />
      <Timer
        timeChanged={timeChangedHandler}
        minute={selectedMinute} // Use state value
        second={selectedSecond} // Use state value
        minTime={TimeService.convertToTimeStringMinuteSeconds(0, 1)}
        maxTime={TimeService.convertToTimeStringMinuteSeconds(59, 59)}
        isDisplayingMinSec={true}
        isDisabled={isOn}
      />

      {!isTimeValid && (
        <Period>
          <p className="valid">
            {translate("choose_beetween")}:{" "}
            <b>
              {"00:01"} - {"59:59"}
            </b>
          </p>
        </Period>
      )}
    </Wrapper>
  );
}

export default AutoOffMode;
