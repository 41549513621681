import { setOnDeviceByParams } from "./ApiService";

class Device {
  constructor(deviceConfig) {
    Object.assign(this, deviceConfig);
  }

  getStatusValue = (propertyToCheck) => {
    if (!this.statusList) {
      return 0; // Handle the case where deviceToCheck is undefined or statusList is not present
    }

    let propertyValue = 0;

    for (const item of this.statusList) {
      if (item.code === propertyToCheck) {
        propertyValue = item.value;
        break;
      }
    }

    return propertyValue;
  };

  getStatusValueFromProperties = (propertiesToCheck) => {
    if (!this.statusList) {
      return 0; // Handle the case where deviceToCheck is undefined or statusList is not present
    }

    let propertyToCheck = this.findExistingPropertyFromList(propertiesToCheck);

    if (propertyToCheck === null || propertiesToCheck === "") {
      return 0;
    }

    let propertyValue = 0;

    for (const item of this.statusList) {
      if (item.code === propertyToCheck) {
        propertyValue = item.value;
        break;
      }
    }

    return propertyValue;
  };

  findExistingPropertyFromList(properties) {
    return properties.find((prop) => this.checkIfPropertyExists(prop)) || "";
  }

  checkIfPropertyExists = (propertyToCheck) => {
    if (!this || !this.statusList) {
      return false; // Handle the case where deviceToCheck is undefined or statusList is not present
    }

    if (
      this.deviceConfig &&
      this.deviceConfig.hiddenProperties.includes(propertyToCheck)
    ) {
      return false; // Handle the case where deviceToCheck is undefined or statusList is not present
    }

    return this.statusList.some((item) => item.code === propertyToCheck);
  };

  getStatusValueInTempScale(propertyToCheck) {
    const fromValue = this.getStatusValue(propertyToCheck);
    return this.convertToTempScale(fromValue);
  }

  getFromRange(propertyToCheck) {
    if (!this.deviceConfig.propertiesRanges) {
      return 0;
    }

    const property = this.deviceConfig.propertiesRanges[propertyToCheck];
    const fromValue = property ? property.from : 0;

    return fromValue;
  }

  getFromRangeInTempScale(propertyToCheck) {
    const fromValue = this.getFromRange(propertyToCheck);
    return this.convertToTempScale(fromValue);
  }

  getToRange(propertyToCheck) {
    if (!this.deviceConfig.propertiesRanges) {
      return 0;
    }

    const property = this.deviceConfig.propertiesRanges[propertyToCheck];
    const toValue = property ? property.to : 0;

    return toValue;
  }

  getToRangeInTempScale(propertyToCheck) {
    const toValue = this.getToRange(propertyToCheck);
    return this.convertToTempScale(toValue);
  }

  getStepRange(propertyToCheck) {
    if (!this.deviceConfig.propertiesRanges) {
      return 0;
    }

    const property = this.deviceConfig.propertiesRanges[propertyToCheck];
    const stepValue = property ? property.step : 0;

    return stepValue;
  }

  getStepRangeInTempScale(propertyToCheck) {
    const stepValue = this.getStepRange(propertyToCheck);
    return this.convertToTempScale(stepValue);
  }

  setOnDeviceByParams(command, value) {
    console.log(command + " value: " + value);

    setOnDeviceByParams(this.id, command, value)
      .then((response) => {
        // this.statusList[command] = value;
        console.log("Data posted successfully");
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  }

  setOnDeviceByParamsInTempScale(command, value) {
    const valueInTempScale = this.convertBackFromScaledValue(value);
    console.log(command + " value: " + valueInTempScale);

    setOnDeviceByParams(this.id, command, valueInTempScale)
      .then((response) => {
        console.log("Data posted successfully");
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  }

  getEnumValues(enumName, translate) {
    if (
      !this.deviceConfig ||
      !this.deviceConfig.enumConfig ||
      !this.deviceConfig.enumConfig[enumName]
    ) {
      return [
        {
          value: "",
          label: "",
        },
      ];
    }

    return this.deviceConfig.enumConfig[enumName].possibleValues.map(
      (item) => ({
        value: item.value,
        label: translate(item.translationKey),
      })
    );
  }

  getSelectedEnum(enumName, translate) {
    const enumValues = this.getEnumValues(enumName, translate);
    const valueOnDevice = this.getStatusValue(enumName);

    // Find the enum in the enumValues array that matches the valueOnDevice
    const selectedEnum = enumValues.find(
      (item) => item.value === valueOnDevice
    );

    // If found, return the whole object or just the label
    return selectedEnum ? selectedEnum : { value: "", label: "" };
  }

  convertToTempScale(value) {
    return value * this.deviceConfig.temperatureScale;
  }

  convertBackFromScaledValue(value) {
    return Math.round((1 / this.deviceConfig.temperatureScale) * value);
  }

  containsRunMode(runMode) {
    const isRunModeAvailable =
      this.deviceConfig != null &&
      this.deviceConfig.runModes != null &&
      this.deviceConfig.runModes[runMode] != null;

    return isRunModeAvailable;
  }

  getRunMode(runMode) {
    const isRunModeAvailable =
      this.deviceConfig != null &&
      this.deviceConfig.runModes != null &&
      this.deviceConfig.runModes[runMode] != null;

    return isRunModeAvailable ? this.deviceConfig.runModes[runMode] : "";
  }

  getRunModeByValue(value) {
    if (this.deviceConfig && this.deviceConfig.runModes) {
      for (const runMode in this.deviceConfig.runModes) {
        if (this.deviceConfig.runModes[runMode] === value) {
          return runMode;
        }
      }
    }
    return null; // If no matching runMode is found, return null
  }
}

export default Device;
