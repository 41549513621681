import { debounce } from "lodash";

class DebounceService {
  /**
   * Initializes a new instance of the DebounceService class.
   */
  constructor() {
    if (DebounceService.instance) {
      return DebounceService.instance;
    }

    DebounceService.instance = this;

    // // Throttle the setTemperature method to only allow one call every 200 milliseconds
    // this.setTemperature = debounce(this.setTemperature.bind(this), 200, {
    //   trailing: true,
    // });

    this.setValueWithDebounce = debounce(
      this.setValueWithDebounce.bind(this),
      200,
      {
        trailing: true,
      }
    );
  }

  /**
   * Retrieves the single instance of the DebounceService class.
   * If an instance does not exist, it creates a new one.
   * @returns {DebounceService} The single instance of the DebounceService class.
   */
  static getInstance() {
    if (!DebounceService.instance) {
      DebounceService.instance = new DebounceService();
    }

    return DebounceService.instance;
  }

  setValueWithDebounce(callback, value) {
    callback(value);
  }
}

export default DebounceService;
