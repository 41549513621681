import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
//STYLES
import styled from "styled-components";
import { theme } from "../../utils/theme";

//SERVICES
import Device from "../../services/Device";
import { useLanguage } from "../../services/LanguageContext";
import { loadDeviceDetails, getDeviceLogs } from "../../services/ApiService";
import SignalRService from "../../services/SignalRService";

//DATA
import { dropdown } from "../../data/data";
//COMPONENTS
import RouteLayout from "../../layout/RouteLayout";
import TrybPracy from "./Termostat/TermostatWorkingMode";
import { FullCardWrapper, Heading, Loader } from "../../utils/utilsstyles";
import { Label } from "../../utils/typo";
import LeftMenuSettings from "./LeftMenuSettings";
import { Ikonka } from "../Icons/Icon";
import CircIconLabeled from "../Icons/CircIconLabeled";
import Switch from "../Switch/Switch";
import Dropdown from "../Forms/Dropdown";
import { MutatingDots } from "react-loader-spinner";
import PrimaryDeviceSettings from "./PrimaryDeviceSettings";
import ButtonRounded from "../Button/ButtonRounded";
import Modal from "../Modals/Modal";

const Wrapper = styled.div`
  z-index: 10;
  background-color: ${theme.color.sec7};
`;

const AllSettingsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
`;

const WiredWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  > div {
    display: grid;
    grid-template-columns: auto 2fr;
    column-gap: 1rem;
    background-color: ${theme.color.secondLight};
    padding: 1.5rem;
    border-radius: 10px;
    > div {
      display: flex;
      flex-direction: column;
      justify-self: center;
      align-self: center;

      &:nth-of-type(2) {
        background-color: white;
        padding: 2px 8px;
        border-radius: 8px;
        border: 1px solid lightgray;
        width: 100%;
      }
      svg {
        fill: ${theme.color.second};
        justify-self: center;
        align-self: center;
        width: 32px;
      }
      h6 {
        color: ${theme.color.second};
        font-size: 16px;
        font-weight: 400;
        justify-self: center;
        align-self: center;
      }
    }
  }
`;

const WirelessWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  > div {
    display: grid;
    grid-template-columns: auto 2fr;
    column-gap: 1rem;
    background-color: ${theme.color.secondLight};
    padding: 1.5rem;
    border-radius: 10px;

    p {
      align-self: center;
    }
    > div {
      display: flex;
      flex-direction: column;
      justify-self: center;
      align-self: center;
      padding-right: 1rem;
      border-right: 1px solid lightgray;

      svg {
        fill: ${theme.color.second};
        justify-self: center;
        align-self: center;
        width: 32px;
        height: auto;
      }
      h6 {
        color: ${theme.color.second};
        font-size: 16px;
        font-weight: 400;
        justify-self: center;
        align-self: center;
      }
    }

    &.active {
      background-color: ${theme.color.base1};

      > div {
        border-color: white;
      }

      svg {
        fill: white;
      }

      p,
      h6 {
        color: white;
      }
    }
  }
`;

const OneSetting = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const LogWrapper = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  margin-top: 1rem;
  column-gap: 1rem;
  > div {
    display: flex;
    flex-direction: column;

    h6 {
      font-size: 18px;
      line-height: 1.2;
    }
  }
`;

const LogButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 3rem;
  row-gap: 1rem;
  margin-top: 1rem;
  column-gap: 1rem;

  > div {
    display: grid;
    grid-template-columns: 70px 1fr 20px;
    border-radius: 8px;
    border: 1px solid lightgray;
    padding: 0.5rem 0.5rem;
    background-color: white;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    align-items: center;

    svg {
      height: 36px;
      width: 40px;
      fill: ${theme.color.base1};
      margin-left: 3px;

      &:nth-of-type(2) {
        transform: rotate(90deg);
        height: 10px;
        margin-left: -10px;
      }

      &:hover {
        background-color: transparent;
      }
    }

    h6 {
      font-size: 18px;
      font-weight: 500;
      display: block;
      border-left: 1px solid lightgrey;
      margin-left: -1rem;
      padding-left: 1rem;
      transition: all 0.4s ease-in-out;
    }

    &:hover {
      background-color: ${theme.color.prim1};
      h6 {
        color: ${theme.color.prim9};
      }
    }
  }
`;

function ControlBoxSettings({ onClick, title, type }) {
  const { id } = useParams(); // Get the id from the URL
  const [device, setDevice] = useState(null);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedLogItem, setSelectedLogItem] = useState(null);

  const { translate } = useLanguage();

  const [isModalZoneOpened, setIsModalZoneOpened] = useState(false);
  const toggleModalZone = (zoneName, logItems) => {
    if (!isModalZoneOpened) {
      setSelectedLogItem({ zoneName: zoneName, items: logItems });
    } else {
      setSelectedLogItem(null);
    }

    setIsModalZoneOpened(!isModalZoneOpened);
  };

  // Fetch the initial device details
  useEffect(() => {
    const fetchDeviceDetails = async () => {
      const fetchedDeviceDetails = await loadDeviceDetails(id); // Use the id from the URL
      console.log("Thermostat details", fetchedDeviceDetails);
      setDevice(new Device(fetchedDeviceDetails));

      const logsFromApi = await getDeviceLogs(id); // Use the id from the URL
      setLogs(logsFromApi);

      setLoading(false);
    };

    fetchDeviceDetails();
  }, [id]);

  // Register to SignalRService when the component mounts and unregister when it unmounts
  useEffect(() => {
    const handleUpdateDeviceStatus = (updatedDevice) => {
      // console.log("Update recieved from SignalR");

      if (updatedDevice.id === id) {
        console.log("Update recieved from SignalR");
        setDevice(new Device(updatedDevice));
      }
    };

    SignalRService.subscribe("UpdateDeviceStatus", handleUpdateDeviceStatus);

    return () => {
      SignalRService.unsubscribe(
        "UpdateDeviceStatus",
        handleUpdateDeviceStatus
      );
    };
  }, [id]);

  const getTimeString = (timestamp) => {
    const date = new Date(timestamp);

    // Function to pad single digit numbers with a leading zero
    const pad = (number) => (number < 10 ? "0" + number : number);

    // Extract date components in local time
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Months are zero-based
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    // Return formatted date string
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  if (loading) {
    return (
      <Loader>
        <MutatingDots
          visible={true}
          height="100"
          width="100"
          color={theme.color.base1}
          secondaryColor={theme.color.base1}
          radius="12.5"
          ariaLabel="mutating-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </Loader>
    ); // Or any other loading indicator
  }

  return (
    <Wrapper>
      <RouteLayout onClick="powrót" title={device.name} type="controlBox" />
      <AllSettingsWrapper>
        <FullCardWrapper>
          <Heading>
            <h3>Strefy</h3>
          </Heading>
          <Label>Strefy przewodowe</Label>
          <WiredWrapper>
            <div className={device.getStatusValue("zone1") ? "active" : ""}>
              <div>
                <Ikonka name="wire" />
                <h6>Wired</h6>
              </div>
              <div>Zone A</div>
            </div>
            <div className={device.getStatusValue("zone2") ? "active" : ""}>
              <div>
                <Ikonka name="wire" />
                <h6>Wired</h6>
              </div>
              <div>Zone B</div>
            </div>
          </WiredWrapper>
          <Label>Strefy bezprzewodowe</Label>
          <WirelessWrapper>
            <div className={device.getStatusValue("switch_1") ? "active" : ""}>
              <div>
                <Ikonka name="wireless" />
                <h6>Wireless</h6>
              </div>
              <p>Zone 1</p>
            </div>
            <div className={device.getStatusValue("switch_2") ? "active" : ""}>
              <div>
                <Ikonka name="wireless" />
                <h6>Wireless</h6>
              </div>
              <p>Zone 2</p>
            </div>
            <div className={device.getStatusValue("switch_3") ? "active" : ""}>
              <div>
                <Ikonka name="wireless" />
                <h6>Wireless</h6>
              </div>
              <p>Zone 3</p>
            </div>
            <div className={device.getStatusValue("switch_4") ? "active" : ""}>
              <div>
                <Ikonka name="wireless" />
                <h6>Wireless</h6>
              </div>
              <p>Zone 4</p>
            </div>
            <div className={device.getStatusValue("switch_5") ? "active" : ""}>
              <div>
                <Ikonka name="wireless" />
                <h6>Wireless</h6>
              </div>
              <p>Zone 5</p>
            </div>
            <div className={device.getStatusValue("switch_6") ? "active" : ""}>
              <div>
                <Ikonka name="wireless" />
                <h6>Wireless</h6>
              </div>
              <p>Zone 6</p>
            </div>
          </WirelessWrapper>
          <OneSetting>
            <CircIconLabeled
              name="time"
              label="Opóźnienie wyłączenia pompy/kotła"
            />
            {/* <Dropdown className="minWidth" /> */}

            <Dropdown
              className="minWidth"
              dropdown={device.getEnumValues("pump_delay_time", translate)}
              defaultValue={device.getSelectedEnum(
                "pump_delay_time",
                translate
              )}
              handleChange={(selectedItem) => {
                device.setOnDeviceByParams(
                  "pump_delay_time",
                  selectedItem.value
                );
              }}
            />
          </OneSetting>

          <OneSetting>
            <CircIconLabeled
              name="status"
              label={translate("cauldron")}
              className={device.getStatusValue("pump_state") ? "active" : "off"}
            />
          </OneSetting>

          <OneSetting>
            <CircIconLabeled
              name="hydro"
              label={translate("pomp")}
              className={
                device.getStatusValue("boiler_state") ? "active" : "off"
              }
            />
          </OneSetting>
        </FullCardWrapper>

        <FullCardWrapper>
          <PrimaryDeviceSettings
            device={device}
            name={device.name}
          ></PrimaryDeviceSettings>
          <hr />

          {
            //Nowe logi:
          }

          <LogButtonsWrapper>
            {Object.entries(logs)
              .sort(([keyA], [keyB]) => keyA.localeCompare(keyB)) // Sorting by switchKey
              .map(([switchKey, logItems]) => (
                <div
                  key={switchKey}
                  onClick={() => toggleModalZone(switchKey, logItems)}
                >
                  <Ikonka name="time" />
                  <h6>{translate("controlbox_" + switchKey)}</h6>
                  <Ikonka className="arrow" name="up" />
                </div>
              ))}
          </LogButtonsWrapper>
        </FullCardWrapper>
      </AllSettingsWrapper>

      {isModalZoneOpened && (
        <Modal
          title={translate("controlbox_" + selectedLogItem.zoneName)}
          onClose={() => toggleModalZone("", [])}
          isValid={false}
        >
          {selectedLogItem.items.map((item) => (
            <LogWrapper>
              <ButtonRounded
                text={item.value === "true" ? "ON" : "OFF"}
                className={item.value === "true" ? "on" : "off"}
              />
              <div>
                <h6>{translate("controlbox_" + item.code)}</h6>
                <p>{getTimeString(item.eventTime)}</p>
              </div>
            </LogWrapper>
          ))}
        </Modal>
      )}
    </Wrapper>
  );
}

export default ControlBoxSettings;
