import React, { useState, useEffect } from "react";
//STYLES
import styled from "styled-components";
//DATA
import { week } from "../../../../data/data";
//Utils
import { useLanguage } from "../../../../services/LanguageContext";
import TimeService from "../../../../services/TimeService";
import { setCycleTimingEntry } from "../../../../services/ApiService";
//COMPONENTS
import { Heading, ModeWrapper } from "../../../../utils/utilsstyles";
import SettingTime from "./SettingTime/SettingTime";
import Button from "../../../Button/Button";
import RelayEntrySettingModal from "./RelayEntrySettingModal";
import HourMinute from "../../../../services/utils/HourMinute";

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  flex-direction: column;
  h3 {
    margin-bottom: 5px;
  }
`;

function PeriodMode({ device }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentEntry, setCurrentEntry] = useState(null);
  const [entries, setEntries] = useState(
    device.deviceConfig?.cycleTimingConfig?.entries || []
  );
  const { translate } = useLanguage();

  useEffect(() => {
    if (
      device &&
      device.deviceConfig &&
      device.deviceConfig.cycleTimingConfig
    ) {
      setEntries(device.deviceConfig.cycleTimingConfig.entries);
    }
  }, [device]);

  const toggleModal = () => {
    if (isModalOpen) {
      setCurrentEntry(null); // Reset the currentEntry when closing the modal
    }

    setIsModalOpen(!isModalOpen);
  };

  const entryChangedHandler = async (entry) => {
    const newEntries = [...entries];

    if (currentEntry != null) {
      const index = newEntries.findIndex((e) => e === currentEntry);
      if (index > -1) {
        newEntries[index] = entry;
      }
    } else {
      newEntries.push(entry);
    }

    setEntries(newEntries);

    await setCycleTimingEntry(device.id, newEntries);

    setIsModalOpen(false);
  };

  const handleEditEntry = (entry) => {
    setCurrentEntry(entry);
    setIsModalOpen(true);
  };

  const handleRemoved = async (entry) => {
    const updatedEntries = entries.filter((e) => e !== entry);
    setEntries(updatedEntries);
    await setCycleTimingEntry(device.id, updatedEntries);
  };

  const handleOnOffChanged = async (entry, newIsOnValue) => {
    const updatedEntries = entries.map((e) =>
      e === entry ? { ...e, isOn: newIsOnValue } : e
    );
    setEntries(updatedEntries);
    await setCycleTimingEntry(device.id, updatedEntries);
  };

  return (
    <Wrapper>
      <Heading>
        <h3>{translate("period_mode")}</h3>
      </Heading>
      <ModeWrapper>
        {entries.length < 1 ? (
          <p>{translate("relay_no_period_jobs")}</p>
        ) : (
          entries.map((set, index) => (
            <SettingTime
              key={index}
              entry={set}
              time={TimeService.convertToTimeFormat(
                set.startTime.hour,
                set.startTime.minute
              )}
              finishTime={TimeService.convertToTimeFormat(
                set.endTime.hour,
                set.endTime.minute
              )}
              onDurationTime={TimeService.convertToTimeFormat(
                set.onDuration.hour,
                set.onDuration.minute
              )}
              offDurationTime={TimeService.convertToTimeFormat(
                set.offDuration.hour,
                set.offDuration.minute
              )}
              days={set.daysOfWeek.map((item) => translate(item))}
              isOn={set.isOn}
              onRemoved={handleRemoved}
              editClickedHandler={() => handleEditEntry(set)}
              onOnOffChanged={(newIsOnValue) =>
                handleOnOffChanged(set, newIsOnValue)
              }
            />
          ))
        )}
      </ModeWrapper>
      {entries.length < 10 && (
        <Button variant="white" onClick={toggleModal}>
          + {translate("relay_add_period")}
        </Button>
      )}

      {isModalOpen && (
        <RelayEntrySettingModal
          week={week}
          title={
            currentEntry
              ? translate("relay_edit_period")
              : translate("relay_add_period")
          }
          intro={
            currentEntry
              ? translate("relay_editing_period")
              : translate("relay_adding_period")
          }
          initialWeekDays={currentEntry?.daysOfWeek}
          initialStartTime={currentEntry?.startTime ?? null}
          initialEndTime={currentEntry?.endTime ?? null}
          initialIsOn={currentEntry?.isOn ?? null}
          initialOnTime={currentEntry?.onDuration ?? new HourMinute(0, 0)}
          initialOffTime={currentEntry?.offDuration ?? new HourMinute(0, 0)}
          isSupportingOnOffTimes={true}
          toggleModal={toggleModal}
          entryChangedHandler={entryChangedHandler}
          entry={currentEntry}
        />
      )}
    </Wrapper>
  );
}

export default PeriodMode;
