import React, { useState, useEffect } from "react";
//STYLES
import styled from "styled-components";
import { theme } from "../../utils/theme";
//Utils
import { useLanguage } from "../../services/LanguageContext";
//Components
import Dropdown from "./Dropdown";
import { hours, minutes, seconds } from "../../data/data";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 300px;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const Label = styled.label`
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 3px;
  color: ${theme.color.sec5};
  text-transform: uppercase;
  margin-bottom: 2px;
`;

const SelectWrapper = styled.div`
  display: grid;
  grid-template-columns: 120px 120px;
  column-gap: 1rem;

  > div {
    display: flex;

    > div {
      align-self: stretch;
    }
  }
`;

const Timer = ({
  label,
  hour,
  minute,
  second,
  minTime,
  maxTime,
  timeChanged,
  isDisabled,
  isDisplayingMinSec,
  initialHourMax = 23,
}) => {
  const { translate } = useLanguage();

  const [selectedHour, setSelectedHour] = useState(
    hour === undefined ? 0 : hour
  );
  const [selectedMinute, setSelectedMinute] = useState(minute);
  const [selectedSecond, setSelectedSecond] = useState(
    second === undefined ? 0 : second
  );
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    // Update the state when minute or second props change
    if (minute !== 0 && second !== 0) {
      setSelectedMinute(minute ?? 0);
      setSelectedSecond(second ?? 0);
    }
  }, [minute, second]); // Dependency array includes minute and second

  useEffect(() => {
    validateTime();
  }, [selectedHour, selectedMinute, selectedSecond]);

  const validateTime = () => {
    const referenceDate = new Date(1970, 0, 1);
    const selectedDateTime = new Date(referenceDate);
    selectedDateTime.setHours(selectedHour, selectedMinute, selectedSecond);

    const minDateTime = new Date(referenceDate);
    minDateTime.setHours(
      new Date(minTime).getHours(),
      new Date(minTime).getMinutes(),
      new Date(minTime).getSeconds()
    );

    const maxDateTime = new Date(referenceDate);
    maxDateTime.setHours(
      new Date(maxTime).getHours(),
      new Date(maxTime).getMinutes(),
      new Date(maxTime).getSeconds()
    );

    let isValid =
      selectedDateTime >= minDateTime && selectedDateTime <= maxDateTime;

    if (isDisplayingMinSec) {
      timeChanged(selectedMinute, selectedSecond, isValid);
    } else {
      timeChanged(selectedHour, selectedMinute, isValid);
    }
  };

  const handleHourChange = (value) => {
    setSelectedHour(value);
  };

  const handleMinuteChange = (value) => {
    setSelectedMinute(value);
    if (value === 0) {
      // If minutes are set to 00, reset seconds to avoid invalid selection
      setSelectedSecond(0);
    }
  };

  const handleSecondChange = (value) => {
    setSelectedSecond(value);
  };

  const getOptionsForSeconds = () => {
    // If minute is 0, exclude 00 from seconds options
    if (selectedMinute === 0) {
      return seconds.filter((sec) => sec.value !== 0);
    }
    return seconds;
  };

  const getOptionsForHours = () => {
    return hours.filter((hour) => hour.value <= initialHourMax);
  };

  const getHourByValue = (value) => {
    return hours.find((hour) => hour.value === value) || null;
  };

  const getMinuteByValue = (value) => {
    return minutes.find((minute) => minute.value === value) || null;
  };

  const getSecondByValue = (value) => {
    return seconds.find((second) => second.value === value) || null;
  };

  return (
    <Wrapper>
      <Label>{label || translate("setTime", "Set time")}</Label>
      <SelectWrapper>
        {!isDisplayingMinSec && (
          <Dropdown
            className="minWidth"
            placeholder="00 h"
            dropdown={getOptionsForHours()}
            defaultValue={getHourByValue(selectedHour)}
            readOnly={isDisabled}
            handleChange={(selectedItem) =>
              handleHourChange(selectedItem.value)
            }
          />
        )}
        <Dropdown
          className="minWidth"
          placeholder="00 min"
          dropdown={minutes}
          defaultValue={getMinuteByValue(selectedMinute)}
          readOnly={isDisabled}
          handleChange={(selectedItem) =>
            handleMinuteChange(selectedItem.value)
          }
        />
        {isDisplayingMinSec && (
          <Dropdown
            className="minWidth"
            placeholder="00 sec"
            dropdown={getOptionsForSeconds()} // Use the filtered seconds options
            readOnly={isDisabled}
            defaultValue={getSecondByValue(selectedSecond)}
            handleChange={(selectedItem) =>
              handleSecondChange(selectedItem.value)
            }
          />
        )}
      </SelectWrapper>
    </Wrapper>
  );
};

export default Timer;
