import React, { useState } from "react";
//STYLES
import styled from "styled-components";
//DATA
import { week, day_hours } from "../../../../data/data";
import { settingTimes } from "../../../../data/data";
//Utils
import { useLanguage } from "../../../../services/LanguageContext";
//COMPONENTS
import { Heading, ModeWrapper } from "../../../../utils/utilsstyles";
import SettingTime from "./SettingTime/SettingTime";
import Modal from "../../../Modals/Modal";
import Button from "../../../Button/Button";
import ButtonRounded from "../../../Button/ButtonRounded";
import { ButtonsWrapper } from "../../Termostat/WorkingModes/HarmonogramMode";
import Timer from "../../../Forms/Timer";
import Switch from "../../../Switch/Switch";

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  flex-direction: column;
  h3 {
    margin-bottom: 5px;
  }
`;

function HarmonogramMode() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const { translate } = useLanguage();
  return (
    <Wrapper>
      <Heading>
        <h3>{translate("harmonograms", "Harmonograms")}</h3>
      </Heading>
      <ModeWrapper>
        {settingTimes.map((set) => (
          <SettingTime
            time={set.time}
            days={set.days}
            onRemove
            toggleSwitch
            isOn={set.isOn}
          />
        ))}
      </ModeWrapper>
      <Button variant="white" onClick={toggleModal}>
        {translate("addHarmonogram", "+ Add Harmonogram")}
      </Button>
      {isModalOpen && (
        <Modal
          title={translate("addHarmonogram", "Dodaj harmonogram")}
          intro={translate("addHarmonogram", "Dodawanie harmonogramu")}
          onClose={toggleModal}
          confirmText={translate("add", "Dodaj")}
          onConfirm={"toDo"}
        >
          <ButtonsWrapper>
            {week.map((day) => (
              <ButtonRounded
                key={day.name}
                text={day.label}
                active={day.active}
              />
            ))}
          </ButtonsWrapper>
          <Timer />
          <Switch label="On" labelOff="Off" />
        </Modal>
      )}
    </Wrapper>
  );
}

export default HarmonogramMode;
